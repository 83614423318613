import { useState, useEffect } from 'react';
import { Box, Button, Popover, Typography, Grid } from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import { Link, useLocation } from '@remix-run/react';
import { useLoaderData } from '@remix-run/react';
import { useTranslation } from 'react-i18next';

interface Props {
	title: string;
	id: string;
	items: Array<PageItem>;
	colorInvert?: boolean;
}

const NavItem = ({ title, id, items, colorInvert = false }: Props): JSX.Element => {
	let { t } = useTranslation(['common']);
	const data = useLoaderData();
	const { userLanguage } = data;
	const link_prefix = userLanguage === 'en' ? '/en' : '';

	const theme = useTheme();
	let location = useLocation();

	const [anchorEl, setAnchorEl] = useState(null);
	const [openedPopoverId, setOpenedPopoverId] = useState(null);

	const handleClick = (event: any, popoverId: any) => {
		setAnchorEl(event.target);
		setOpenedPopoverId(popoverId);
	};

	const handleClose = (): void => {
		setAnchorEl(null);
		setOpenedPopoverId(null);
	};

	const [activeLink, setActiveLink] = useState('');
	// useEffect(() => {
	//   setActiveLink(window && window.location ? window.location.pathname : '');
	// }, []);

	const hasActiveLink = () => items.find((i) => i.href === activeLink);
	const linkColor = colorInvert ? 'common.white' : 'text.primary';

	useEffect(() => {
		let cutLocation = location.pathname.replace(`/${userLanguage}`, '');
		// console.log(location.pathname);
		setActiveLink(window && window.location ? cutLocation : '');
		// setActiveLink(window && window.location ? window.location.pathname : '');
	}, [location]);

	return (
		<Box>
			<Box
				display={'flex'}
				alignItems={'center'}
				aria-label={id}
				sx={{ cursor: 'pointer', textAlign: 'center', userSelect: 'none' }}
				onClick={(e) => handleClick(e, id)}
			>
				<Typography
					fontWeight={openedPopoverId === id || hasActiveLink() ? 700 : 400}
					color={linkColor}
					sx={{
						'&::before': {
							textAlign: 'center',
							display: 'block',
							content: `"${title}"`,
							fontWeight: '700',
							height: 0,
							overflow: 'hidden',
							visibility: 'hidden',
						},
					}}
				>
					{title}
				</Typography>
				<ExpandMoreIcon
					sx={{
						marginLeft: theme.spacing(1 / 4),
						width: 16,
						height: 16,
						transform: openedPopoverId === id ? 'rotate(180deg)' : 'none',
						color: linkColor,
					}}
				/>
			</Box>
			<Popover
				elevation={3}
				id={id}
				open={openedPopoverId === id}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
				sx={{
					'.MuiPaper-root': {
						maxWidth: items.length > 12 ? 350 : 240,
						padding: 2,
						marginTop: 2,
						borderTopRightRadius: 0,
						borderTopLeftRadius: 0,
						borderBottomRightRadius: 0,
						borderBottomLeftRadius: 0,
						borderTop: `1px solid ${theme.palette.text.secondary}`,
					},
				}}
			>
				<Grid container spacing={0.5}>
					{items.map((p, i) => (
						<Grid item key={i} xs={items.length > 12 ? 6 : 12}>
							<Link
								// to={p.href}
								to={link_prefix + p.href}
								prefetch='intent'
								style={{
									textDecoration: 'none',
								}}
								unstable_viewTransition={true}
								onClick={(e) => {
									// setTimeout(() => {
									//   window.location.reload();
									// }, 150);
									setTimeout(() => {
										handleClose();
									}, 150);

									e.stopPropagation();
									// e.preventDefault();
								}}
							>
								<Button
									component={'div'}
									fullWidth
									sx={{
										borderRadius: 0,
										justifyContent: 'flex-start',
										color:
											activeLink === p.href
												? theme.palette.primary.main
												: theme.palette.text.primary,
										backgroundColor:
											activeLink === p.href
												? alpha(theme.palette.primary.main, 0.1)
												: 'transparent',
										// fontWeight: activeLink === p.href ? 600 : 400,
									}}
								>
									{p.title}
									{p.isNew && (
										<Box
											padding={0.5}
											display={'inline-flex'}
											borderRadius={0}
											bgcolor={'secondary.light'}
											marginLeft={2}
										>
											<Typography
												variant={'caption'}
												sx={{ color: 'background.default', lineHeight: 1 }}
											>
												{t('new')}
											</Typography>
										</Box>
									)}
								</Button>
							</Link>
						</Grid>
					))}
				</Grid>
			</Popover>
		</Box>
	);
};

export { NavItem };
