import { Theme, responsiveFontSizes } from '@mui/material';
import { createTheme, ComponentsOverrides } from '@mui/material/styles';
import shadows from './shadows';
import { light, dark } from './palette';
// import './index.css';

const getTheme = (mode: string, isMobile: boolean, themeToggler: () => void, theme: any): Theme =>
	responsiveFontSizes(
		createTheme({
			palette: mode === 'light' ? light : dark,
			shadows: shadows(mode),
			typography: {
				fontFamily: 'AllianceNo2, sans-serif',
				button: {
					textTransform: 'none',
					fontWeight: 'medium' as React.CSSProperties['fontWeight'],
				},
			},
			zIndex: {
				appBar: 1200,
				drawer: 1300,
			},
			components: {
				MuiTypography: {
					styleOverrides: {
						h6: {
							[theme.breakpoints.up('xs')]: {
								fontSize: '1.15rem',
							},
						},
					},
				},
				MuiUseMediaQuery: {
					defaultProps: {
						defaultMatches: true,
						// noSsr: true,
						// matches: mediaQuery.match(query, {
						//   // The estimated CSS width of the browser.
						//   width: deviceType === 'mobile' ? '0px' : '1024px',
						// }),
						ssrMatchMedia: () => ({
							matches: isMobile ? false : true,
							// matches: true,
						}),
					},
				},
				MuiButton: {
					styleOverrides: {
						root: {
							fontWeight: 400,
							borderRadius: 0,
							paddingTop: 10,
							paddingBottom: 10,
						},
						containedSecondary: mode === 'light' ? { color: 'white' } : {},
					} as ComponentsOverrides['MuiButton'],
				},
				MuiInputBase: {
					styleOverrides: {
						root: {
							borderRadius: 0,
						},
					} as ComponentsOverrides['MuiInputBase'],
				},
				MuiOutlinedInput: {
					styleOverrides: {
						root: {
							borderRadius: 0,
						},
						input: {
							borderRadius: 0,
						},
					} as ComponentsOverrides['MuiOutlinedInput'],
				},
				MuiCard: {
					styleOverrides: {
						root: {
							borderRadius: 0,
						},
					} as ComponentsOverrides['MuiCard'],
				},
			},
			themeToggler,
			breakpoints: {
				values: {
					xs: 0,
					sm: 600,
					tabletFooterSM: 660,
					md: 900,
					tabletFooterMD: 1010,
					lg: 1200,
					xl: 1536,
				},
			},
		}),
	);

export default getTheme;

declare module '@mui/material/styles' {
	interface BreakpointOverrides {
		xs: true;
		sm: true;
		tabletFooterSM: true;
		md: true;
		tabletFooterMD: true;
		lg: true;
		xl: true;
	}
}
