import { useState, memo, lazy } from 'react';
import {
	IconButton,
	Checkbox,
	Button,
	Box,
	Typography,
	useMediaQuery,
	useTheme,
	alpha,
	TextField,
	Grid,
} from '@mui/material';
import { Menu as MenuIcon } from '@mui/icons-material';
// import MenuIcon from '@mui/icons-material/Menu';
// import LogoFont from '~/content/svg/logos/NorthRock.png';
import LogoFontDark from '~/content/svg/logos/NorthRock_night.webp';
import { ThemeModeToggler } from '../index';
import { CCToggler } from '../index';
import { LanguageToggler } from '../index';
import { NavItem } from './components';
import { NavItemSingle } from './components/NavItemSIngle';
import { Link } from '@remix-run/react';
import { useTranslation } from 'react-i18next';
import { useLoaderData } from '@remix-run/react';
// import { motion, AnimatePresence } from 'framer-motion';
import { m, LazyMotion } from 'framer-motion';
const loadFeatures = () => import('~/utils/framerFeatures').then((res) => res.domMax);

interface Props {
	// eslint-disable-next-line @typescript-eslint/ban-types
	onSidebarOpen: () => void;
	// pages: {
	//   service: Array<PageItem>;
	//   company: Array<PageItem>;
	// };
	pages: () => any;
	colorInvert?: boolean;
}

// const Topbar = ({ onSidebarOpen, pages, colorInvert = false }: Props): JSX.Element => {
const Topbar = memo(({ onSidebarOpen, pages, colorInvert = false }: Props): JSX.Element => {
	let { t } = useTranslation(['topbar']);
	const { userLanguage } = useLoaderData();
	const link_prefix = userLanguage === 'en' ? '/en' : '';
	const link_root = userLanguage !== 'de' ? userLanguage + '/' : '';

	const theme = useTheme();
	const { mode } = theme.palette;
	const { service: servicesPages, company: companyPages } = pages();
	// const { service: servicesPages, company: companyPages } = pages;

	const [activeHover, setActiveHover] = useState<string | undefined>(undefined);
	// React.useEffect(() => {
	// 	console.log('activeHover: ', activeHover);
	// }, [activeHover]);

	const item = {
		show: {
			transition: {
				duration: 0.2,
			},
		},
	};

	return (
		<Box
			display={'flex'}
			justifyContent={'space-between'}
			alignItems={'center'}
			width={1}
			sx={{ paddingButtom: '10px' }}
		>
			<Link to={'/' + link_root} prefetch='intent'>
				<Box display={'flex'} title='NorthRock' width={{ xs: 155, md: 190 }}>
					{/* <Box alt={"NorthRock Logo"} component={"img"} src={mode === "light" && !colorInvert ? LogoFont : LogoFontDark} height={1} width={1} /> */}
					<Box
						alt={'NorthRock software Logo'}
						component={'img'}
						src={LogoFontDark}
						sx={{
							// minHeight: '53.36px',
							filter: mode === 'light' && !colorInvert ? 'invert(1)' : 'invert(0)',
							userSelect: 'none',
						}}
						height={1}
						width={1}
					/>
				</Box>
			</Link>
			<LazyMotion strict features={loadFeatures}>
				<Box sx={{ display: { xs: 'none', md: 'flex' } }} alignItems={'center'} height={1}>
					<Box
						sx={{ display: { xs: 'none', md: 'flex' }, gap: 4 }}
						height={'44.75px'}
						alignItems={'center'}
						onMouseLeave={() => setActiveHover(undefined)}
						onClick={() => setActiveHover(undefined)}
					>
						<Box
							onMouseEnter={() => setActiveHover('service')}
							position={'relative'}
							// px={1}
							key={'service'}
						>
							<NavItem
								title={t('topbarButton', { context: '1' })}
								id={'service'}
								items={servicesPages}
								colorInvert={colorInvert}
							/>
							{activeHover === 'service' && (
								<m.div
									variants={item}
									animate='show'
									layoutId='underline'
									className='underline-hover'
									style={{ backgroundColor: theme.palette.text.secondary }}
								/>
							)}
						</Box>
						<Box
							onMouseEnter={() => setActiveHover('references')}
							position={'relative'}
							// px={1}
							key={'references'}
						>
							<NavItemSingle
								title={t('topbarButton', { context: '5' })}
								link={'/references/'}
								colorInvert={colorInvert}
							/>
							{activeHover === 'references' && (
								<m.div
									variants={item}
									animate='show'
									className='underline-hover'
									layoutId='underline'
									style={{ backgroundColor: theme.palette.text.secondary }}
								/>
							)}
						</Box>
						<Box
							onMouseEnter={() => setActiveHover('company')}
							position={'relative'}
							// px={1}
							key={'company'}
						>
							<NavItem
								title={t('topbarButton', { context: '2' })}
								id={'company'}
								items={companyPages}
								colorInvert={colorInvert}
							/>
							{activeHover === 'company' && (
								<m.div
									variants={item}
									animate='show'
									className='underline-hover'
									layoutId='underline'
									style={{ backgroundColor: theme.palette.text.secondary }}
								/>
							)}
						</Box>
						<Box
							onMouseEnter={() => setActiveHover('contact')}
							position={'relative'}
							// px={1}
							key={'contact'}
						>
							<NavItemSingle
								title={t('topbarButton', { context: '3' })}
								link={'/contact/'}
								colorInvert={colorInvert}
							/>
							{activeHover === 'contact' && (
								<m.div
									variants={item}
									animate='show'
									className='underline-hover'
									layoutId='underline'
									style={{ backgroundColor: theme.palette.text.secondary }}
								/>
							)}
						</Box>
					</Box>

					<Box paddingLeft={4}>
						<Link
							// to='/hire-us'
							to={link_prefix + '/hire-us/'}
							prefetch='intent'
							onClick={(e) => {
								e.stopPropagation();
							}}
						>
							<Button
								component={'div'}
								variant='contained'
								color='primary'
								size='small'
								sx={{
									borderRadius: 0,
								}}
							>
								{t('topbarButton', { context: '4' })}
							</Button>
						</Link>
					</Box>

					<Box
						sx={{
							ml: 2,
						}}
					>
						<CCToggler />
					</Box>
					<Box
						sx={{
							ml: 2,
						}}
					>
						<ThemeModeToggler />
					</Box>
					<Box
						sx={{
							ml: 2,
						}}
					>
						<LanguageToggler />
					</Box>
				</Box>
			</LazyMotion>

			<Box sx={{ display: { xs: 'flex', md: 'none' } }} alignItems={'center'}>
				<Box
					sx={{
						mr: 1.5,
					}}
				>
					<CCToggler />
				</Box>
				<Box
					sx={{
						mr: 1.5,
					}}
				>
					<ThemeModeToggler />
				</Box>
				<Box
					sx={{
						mr: 1.5,
					}}
				>
					<LanguageToggler />
				</Box>
				<Button
					onClick={() => onSidebarOpen()}
					aria-label='Menu'
					variant={'outlined'}
					sx={{
						borderRadius: 0,
						minWidth: 'auto',
						padding: 1,
						borderColor: alpha(theme.palette.divider, 0.2),
					}}
				>
					<MenuIcon />
				</Button>
			</Box>
		</Box>
	);
});

export { Topbar };
