import { PaletteMode } from '@mui/material';

export const light = {
	alternate: {
		main: '#F6F6F6', // f7f7f7
		dark: '#F6F6F6',
	},
	cardShadow: 'rgba(23, 70, 161, .11)',

	mode: 'light' as PaletteMode,

	primary: {
		main: '#000', //e.g. startpage banner #014770
		light: '#EEEEEE', // 444   0544C8
		dark: '#444',
		contrastText: '#EEEEEE',
	},
	secondary: {
		light: '#000',
		main: '#FF6600',

		// FF6600    sehr gut
		// E84900    gut

		// f9b934	default
		// FF6600 apple gelb
		// EA9E1D  braun gelb
		// F56901 apple orange
		dark: '#0A0A0A',
		contrastText: '#000',
		// contrastText: 'rgba(0, 0, 0, 0.87)',
	},
	text: {
		primary: '#1e2022',
		secondary: '#515151',
		dark: '#444',
	},
	divider: 'rgba(0, 0, 0, 0.12)',
	underline: 'rgba(0, 0, 0, 0.08)',

	background: {
		paper: '#ffffff',
		default: '#ffffff',
		level2: '#F6F6F6',
		level1: '#ffffff',
		footer: '#F6F6F6',
	},
};

export const dark = {
	alternate: {
		main: '#0D1118', //backghround sections and start slide
		dark: '#000',
	},
	cardShadow: 'rgba(0, 0, 0, .11)',
	common: {
		black: '#0A0A0A',
		white: '#fff',
	},
	mode: 'dark' as PaletteMode,

	primary: {
		main: '#F6F6F6',
		light: '#2A2D34', //7FD2AC  23252E
		dark: '#888',
		contrastText: '#1F232A',
	},
	secondary: {
		light: '#fff',
		main: '#FF6600',
		// F6F6F6
		dark: '#1F232A', //testimonial case study
		contrastText: '#aaa',
		// contrastText: 'rgba(0, 0, 0, 0.87)',
	},
	text: {
		primary: '#fff',
		secondary: '#AEB0B4',
		dark: '#888',
	},
	divider: 'rgba(255, 255, 255, 0.22)',
	underline: 'rgba(255, 255, 255, 0.18)',

	background: {
		paper: '#0A0A0A', //old color 222B45
		default: '#0A0A0A',
		level2: '#1F232A', //case studys

		//  282A33  origianl
		// 03181C dunkel grün seiko
		// 000A26 lila frauenhofer
		// 1C1D1F dunkel grau
		// 232529 grau
		// 161617 grau
		// 0D1118 dunkel grau

		level1: '#2A2D34',
		footer: '#000',
	},
};
