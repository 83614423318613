import { Trans, useTranslation } from 'react-i18next';

const pages = () => {
	let { t } = useTranslation(['topbar']);
	// const { t, i18n } = useTranslation();
	// const pages = {

	const service = [
		// {
		//   title: 'Web Entwicklung',
		//   href: 'application-development',
		// },

		// {
		//   title: 'App Entwicklung',
		//   href: '/mobile-app',
		// },

		{
			title: t('services', { context: 1 }),
			href: '/websites/',
		},
		{
			title: t('services', { context: 2 }),
			href: '/applications/',
		},
		{
			title: t('services', { context: 3 }),
			href: '/workflows/',
		},
	];

	const company = [
		{
			title: t('company', { context: 1 }),
			href: '/about/',
			// isNew: true,
		},
		{
			title: t('company', { context: 2 }),
			href: '/network/',
		},
		// {
		//   title: t('company', { context: 3 }),
		//   href: '/customers/',
		// },

		// {
		//   title: "Kontakt",
		//   href: "/contact",
		//   // isNew: true,
		// },

		// {
		// 	title: 'Kunden',
		// 	href: '/customers'
		// },

		// {
		//   title: 'Contact: Map',
		//   href: '/contact-sidebar-map',
		// },
		// {
		//   title: 'Contact: Minimal',
		//   href: '/contact-page-cover',
		// },
	];
	return { service, company };

	// TODO  later
	// career: [
	// 	{
	// 		title: 'Studentische Hilfskraft',
	// 		href: '/career-opening',
	// 		isNew: true
	// 	}
	// ],

	// {
	//   title: 'Werkstudent:in',
	//   href: '/career-opening',
	//   isNew: true,
	// },
	// {
	//   title: 'IT-Manager:in',
	//   href: '/career-opening',
	// },
	// {
	//   title: 'Praktikum',
	//   href: '/career-opening',
	// },
	// {
	//   title: 'Übersicht groß',
	//   href: '/career-listing',
	// },

	// {
	//   title: 'Übersicht',
	//   href: '/career-listing-minimal',
	// },

	// blog: [
	//   {
	//     title: 'Reach View',
	//     href: '/blog-reach-view',
	//   },
	//   {
	//     title: 'Search',
	//     href: '/blog-search',
	//   },
	//   {
	//     title: 'Newsroom',
	//     href: '/blog-newsroom',
	//   },
	//   {
	//     title: 'Article',
	//     href: '/blog-article',
	//   },
	// ],

	// portfolio: [
	//   {
	//     title: 'Grid View',
	//     href: '/portfolio-grid',
	//   },
	//   {
	//     title: 'Masonry',
	//     href: '/portfolio-masonry',
	//   },
	//   {
	//     title: 'Basic',
	//     href: '/portfolio-page',
	//   },
	//   {
	//     title: 'Parallax',
	//     href: '/agency',
	//   },
	// ],

	// account: [
	//   {
	//     title: 'General Settings',
	//     href: '/account-general',
	//   },
	//   {
	//     title: 'Security Settings',
	//     href: '/account-security',
	//   },
	//   {
	//     title: 'Notifications Options',
	//     href: '/account-notifications',
	//   },
	//   {
	//     title: 'Billing Options',
	//     href: '/account-billing',
	//   },
	//   {
	//     title: 'Sign up: Simple',
	//     href: '/signup-simple',
	//   },
	//   {
	//     title: 'Sign up: Cover',
	//     href: '/signup-cover',
	//   },
	//   {
	//     title: 'Sign in: Simple',
	//     href: '/signin-simple',
	//   },
	//   {
	//     title: 'Sign in: Cover',
	//     href: '/signin-cover',
	//   },
	//   {
	//     title: 'Password: Simple',
	//     href: '/password-reset-simple',
	//   },
	//   {
	//     title: 'Password: Cover',
	//     href: '/password-reset-cover',
	//   },
	// ],

	// landings: [
	// 	{
	// 		title: 'Mobile App',
	// 		href: '/mobile-app'
	// 	},
	// 	{
	// 		title: 'Desktop App',
	// 		href: 'application-development'
	// 	},
	// 	{
	// 		title: 'Startup',
	// 		href: '/startup'
	// 	},
	// 	{
	// 		title: 'Expo',
	// 		href: '/expo'
	// 	},
	// 	{
	// 		title: 'Marketing',
	// 		href: '/'
	// 	},
	// 	{
	// 		title: 'Service',
	// 		href: '/service'
	// 	},
	// 	{
	// 		title: 'Enterprise',
	// 		href: '/enterprise'
	// 	},
	// 	{
	// 		title: 'Cloud Hosting',
	// 		href: '/cloud-hosting'
	// 	},
	// 	{
	// 		title: 'Design Company',
	// 		href: '/design-company'
	// 	},
	// 	{
	// 		title: 'Web Basic',
	// 		href: '/web-basic'
	// 	},
	// 	{
	// 		title: 'Overview',
	// 		href: '/home'
	// 	},
	// 	{
	// 		title: 'Agency',
	// 		href: '/agency'
	// 	},
	// 	{
	// 		title: 'E-Learning',
	// 		href: '/e-learning'
	// 	},
	// 	{
	// 		title: 'Coworking',
	// 		href: '/coworking'
	// 	},
	// 	{
	// 		title: 'Rental',
	// 		href: '/rental'
	// 	},
	// 	{
	// 		title: 'Job Listing',
	// 		href: '/job-listing'
	// 	},
	// 	{
	// 		title: 'Logistics',
	// 		href: '/logistics'
	// 	},
	// 	{
	// 		title: 'E-commerce',
	// 		href: '/e-commerce'
	// 	}
	// ]
};

export { pages };
