import { ThemeProvider } from '@mui/material/styles';
import { Paper, CssBaseline } from '@mui/material';
// import CssBaseline from '@mui/material/CssBaseline';
import getTheme from '~/content/theme';
// import AOS from 'aos';
import { useLoaderData } from '@remix-run/react';
import { useTheme } from '@mui/material/styles';

import { useState, useEffect, startTransition, memo } from 'react';

export const useDarkMode = (): [string, () => void, boolean] => {
	// export const useDarkMode = (userPref?: 'light' | 'dark'): [string, () => void, boolean] => {
	const [themeMode, setTheme] = useState('light');
	const [mountedComponent, setMountedComponent] = useState(false);

	const setMode = (mode: string) => {
		try {
			window.localStorage.setItem('themeMode', mode);
			//? enable same window sync -> else its only synced on change from other window
			window.dispatchEvent(new Event('storage'));
		} catch {
			/* do nothing */
		}
		startTransition(() => {
			setTheme(mode);
		});
	};

	const themeToggler = (): void => {
		themeMode === 'light' ? setMode('dark') : setMode('light');
	};

	useEffect(() => {
		try {
			const localTheme = window.localStorage.getItem('themeMode');
			localTheme ? setTheme(localTheme) : setMode('light');
		} catch {
			setMode('light');
		}
		startTransition(() => {
			setMountedComponent(true);
		});
	}, []);

	return [themeMode, themeToggler, mountedComponent];
};

interface Props {
	children: React.ReactNode;
	userPref?: string;
}

// const Styler = () => {
// 	const theme = useTheme();
// 	useEffect(() => {
// 		//? selection/marking color
// 		let style = document.createElement('style');
// 		style.innerHTML = `::selection {
//       // color: ${theme.palette.secondary.main};
//       // color: #7F7F7Faa;
//      color: #7F7F7Fdd;
//       // color: #538297fe;
//       // color: #FF660055;
//       // color: #FF6600dd;
//       // color: #FE681Bee;
//       // background: black;
//     }`;
// 		document.head.appendChild(style);
// 	}, [theme]);

// 	return null;
// };

export function Page({ children, userPref }: Props): JSX.Element {
	// const Page = memo(({ children, userPref }: Props): JSX.Element => {
	const theme = useTheme();
	const { IS_MOBILE } = useLoaderData<any>();
	// const isMobile = loaderData.IS_MOBILE;
	// console.log({ isMobile });

	// useEffect(() => {
	// 	//? Remove the server-side injected CSS.
	// 	const jssStyles = document.querySelector('#jss-server-side');
	// 	if (jssStyles) {
	// 		jssStyles.parentElement?.removeChild(jssStyles);
	// 	}
	// }, []);

	const [themeMode, themeToggler, mountedComponent] = useDarkMode();

	// useEffect(() => {
	//   AOS.refresh();
	//   // console.log('aos refresh');
	// }, [mountedComponent, themeMode]);
	const ssrThemeMode = userPref && !mountedComponent ? userPref : themeMode;
	return (
		// <ThemeProvider theme={getTheme(themeMode, themeToggler)}>
		<ThemeProvider theme={getTheme(ssrThemeMode, IS_MOBILE, themeToggler, theme)}>
			<CssBaseline />
			<Paper elevation={0} sx={{ borderRadius: 0 }}>
				{children}
			</Paper>
			{/* <Styler /> */}
		</ThemeProvider>
	);
}

// export { Page };
