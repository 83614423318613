import { useState, useEffect } from 'react';
import { Box, Button, Popover, Typography, Grid } from '@mui/material';
// import Button from '@mui/material/Button';
import { alpha, useTheme } from '@mui/material/styles';
// import Popover from '@mui/material/Popover';
// import Typography from '@mui/material/Typography';
// import Grid from '@mui/material/Grid';
// import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import { Link, useLocation, useLoaderData, useHref } from '@remix-run/react';

interface Props {
	title: string;
	// id: string;
	// items: Array<PageItem>;
	link: string;
	colorInvert?: boolean;
}
// company: [
//   {
//     title: "Über uns",
//     href: "/about",
//     // isNew: true,
//   },
//   {
//     title: "Netzwerke & Partner",
//     href: "/network",
//     isNew: true,
//   },
//   {
//     title: "Kontakt",
//     href: "/contact",
//     // isNew: true,
//   },
const NavItemSingle = ({ title, link, colorInvert = false }: Props): JSX.Element => {
	const data = useLoaderData();
	const { userLanguage } = data;
	const link_prefix = userLanguage === 'en' ? '/en' : '';

	const theme = useTheme();
	let location = useLocation();
	// console.log(location);

	// const [anchorEl, setAnchorEl] = useState(null);
	// const [openedPopoverId, setOpenedPopoverId] = useState(null);

	// const handleClick = (event: any, popoverId: any) => {
	//   setAnchorEl(event.target);
	//   setOpenedPopoverId(popoverId);
	// };

	// const handleClose = (): void => {
	//   setAnchorEl(null);
	//   setOpenedPopoverId(null);
	// };

	const [activeLink, setActiveLink] = useState('');
	// useEffect(() => {
	//   setActiveLink(window && window.location ? window.location.pathname : '');
	// }, []);
	// const hasActiveLink = () => items.find((i) => i.href === activeLink);
	// const hasActiveLink = link === activeLink;
	const hasActiveLink = activeLink.includes(link);
	const linkColor = colorInvert ? 'common.white' : 'text.primary';

	useEffect(() => {
		let cutLocation = location.pathname.replace(`/${userLanguage}`, '');
		setActiveLink(window && window.location ? cutLocation : '');
		// setActiveLink(window && window.location ? window.location.pathname : '');
		// console.log('location changed', location);
	}, [location]);

	return (
		<Box>
			<Box
				display={'flex'}
				alignItems={'center'}
				sx={{ cursor: 'pointer', textAlign: 'center', userSelect: 'none' }}
			>
				<Link prefetch='intent' to={link_prefix + link} unstable_viewTransition={true}>
					<Typography
						fontWeight={hasActiveLink ? 700 : 400}
						color={linkColor}
						sx={{
							'&::before': {
								textAlign: 'center',
								display: 'block',
								content: `"${title}"`,
								fontWeight: '700',
								height: 0,
								overflow: 'hidden',
								visibility: 'hidden',
							},
						}}
					>
						{title}
					</Typography>
				</Link>
				{/* <ExpandMoreIcon
          sx={{
            marginLeft: theme.spacing(1 / 4),
            width: 16,
            height: 16,
            transform: openedPopoverId === id ? 'rotate(180deg)' : 'none',
            color: linkColor,
          }}
        /> */}
			</Box>
			{/* <Popover
        elevation={3}
        id={id}
        open={openedPopoverId === id}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        sx={{
          '.MuiPaper-root': {
            maxWidth: items.length > 12 ? 350 : 240,
            padding: 2,
            marginTop: 2,
            borderTopRightRadius: 0,
            borderTopLeftRadius: 0,
            borderBottomRightRadius: 0,
            borderBottomLeftRadius: 0,
            borderTop: `1px solid ${theme.palette.primary.main}`,
          },
        }}
      >
        <Grid container spacing={0.5}>
          {items.map((p, i) => (
            <Grid item key={i} xs={items.length > 12 ? 6 : 12}>
              <Link
                to={p.href}
                prefetch='intent'
                style={{
                  textDecoration: 'none',
                }}
                onClick={(e) => {
                  // setTimeout(() => {
                  //   window.location.reload();
                  // }, 150);
                  setTimeout(() => {
                    handleClose();
                  }, 150);

                  e.stopPropagation();
                  // e.preventDefault();
                }}
              >
                <Button
                  component={'div'}
                  fullWidth
                  sx={{
                    borderRadius: 0,
                    justifyContent: 'flex-start',
                    color: activeLink === p.href ? theme.palette.primary.main : theme.palette.text.primary,
                    backgroundColor: activeLink === p.href ? alpha(theme.palette.primary.main, 0.1) : 'transparent',
                    // fontWeight: activeLink === p.href ? 600 : 400,
                  }}
                >
                  {p.title}
                  {p.isNew && (
                    <Box padding={0.5} display={'inline-flex'} borderRadius={0} bgcolor={'secondary.light'} marginLeft={2}>
                      <Typography variant={'caption'} sx={{ color: 'background.default', lineHeight: 1 }}>
                        neu
                      </Typography>
                    </Box>
                  )}
                </Button>
              </Link>
            </Grid>
          ))}
        </Grid>
      </Popover> */}
		</Box>
	);
};

export { NavItemSingle };
