import { useState, useEffect, lazy, Suspense, memo } from 'react';
import { useTheme } from '@mui/material/styles';
import { Skeleton, Box, Divider, AppBar, useScrollTrigger, useMediaQuery } from '@mui/material';
// import Box from '@mui/material/Box';
import Scroll from 'react-scroll';
// import { useHydrated } from 'remix-utils/use-hydrated';

import { Container } from '~/content/components/Container.tsx';
// import { Topbar, Sidebar, Footer, ThemeModeToggler } from './components';
import { Topbar } from './components';
// const Footer = lazy(() => import('./components/Footer/Footer')).then((module) => ({ default: module.Footer }));
// const Topbar = lazy(async () => ({
// 	default: (await import('./components/Topbar/Topbar')).Topbar,
// }));
const Sidebar = lazy(async () => ({
	default: (await import('./components/Sidebar/Sidebar')).Sidebar,
}));
const Footer = lazy(async () => ({
	default: (await import('./components/Footer/Footer')).Footer,
}));

import { useLocation, useNavigation } from '@remix-run/react';
import { useLoaderData } from '@remix-run/react';

import { pages } from '../navigation';
// import { Loader } from '~/content/components/Loader';
const Loader = lazy(async () => ({
	default: (await import('~/content/components/Loader')).Loader,
}));
interface Props {
	children: React.ReactNode;
	colorInvert?: boolean;
	bgcolor?: string;
}
const locationSwitch = (location: string, lang: string) => {
	//* remove lang if present
	// let cutLocation = location.split('/').filter((item) => item !== 'en' && item !== 'de' && item !== '');
	// let cutLocation = location.replace('/en', '').replace('/de', '');
	let cutLocation = location.replace(`/${lang}`, '');
	// console.log({ cutLocation });
	switch (cutLocation) {
		case '/applications/':
			return 'alternate.main';
		case '/websites/':
			return 'alternate.main';
		case '/workflows/':
			return 'alternate.main';
		case '/hire-us/':
			return 'alternate.main';
		default:
			return false;
	}
};

// const Main = ({ children, colorInvert = false, bgcolor = 'transparent' }: Props): JSX.Element => {
const Main = memo(
	({ children, colorInvert = false, bgcolor = 'transparent' }: Props): JSX.Element => {
		const data = useLoaderData();
		const { userLanguage, IS_MOBILE } = data;
		const [openSidebar, setOpenSidebar] = useState(false);

		const [loaded, setLoaded] = useState(false);
		useEffect(() => {
			if (!loaded) {
				setLoaded(true);
			}
			return () => {
				setLoaded(false);
			};
		}, []);

		let location = useLocation();
		// const navigation = useNavigation();

		useEffect(() => {
			setOpenSidebar(false); //* close sidebar on route change
		}, [location]);

		const theme = useTheme();
		const isMd = useMediaQuery(theme.breakpoints.up('md'), {
			defaultMatches: true,
		});

		const isSm = useMediaQuery(theme.breakpoints.up('sm'), {
			defaultMatches: true,
		});

		const handleSidebarOpen = (): void => {
			setOpenSidebar(true);
		};

		const handleSidebarClose = (): void => {
			setOpenSidebar(false);
		};
		const open = isMd ? false : openSidebar;

		const trigger = useScrollTrigger({
			disableHysteresis: true,
			threshold: 38,
		});

		const bgColor = () => {
			const customPageHeaderBG = locationSwitch(location.pathname, userLanguage);
			if (trigger) {
				return theme.palette.background.paper;
			} else if (customPageHeaderBG) {
				return customPageHeaderBG;
			} else {
				return theme.palette.background.paper;
			}
		};

		return (
			<Box>
				{/* <Box bgcolor={bgcolor} position={'relative'} zIndex={theme.zIndex.appBar}>
				<Container paddingTop={'8px !important'} paddingBottom={'0 !important'}>
					<Box display={'flex'} justifyContent={'flex-end'} alignItems={'center'}>
						<Box marginRight={2}>
							<Link
								underline="none"
								component="a"
								href="/blocks"
								color={colorInvert ? 'common.white' : 'text.primary'}
								sx={{ display: 'flex', alignItems: 'center' }}
							>
								Components
								<Box padding={0.5} display={'inline-flex'} borderRadius={1} bgcolor={'primary.main'} marginLeft={1}>
									<Typography variant={'caption'} sx={{ color: 'common.white', lineHeight: 1 }}>
										new
									</Typography>
								</Box>
							</Link>
						</Box>
						<Box marginRight={2}>
							<Link
								underline="none"
								component="a"
								href="/docs/introduction"
								color={colorInvert ? 'common.white' : 'text.primary'}
							>
								Docs
							</Link>
						</Box>
						<Box>
							<ThemeModeToggler />
						</Box>
					</Box>
				</Container>
			</Box> */}
				<AppBar
					position={'sticky'}
					sx={{
						top: 0,
						// bgcolor={'alternate.main'}
						// backgroundColor: trigger ? theme.palette.background.paper : bgcolor,
						// transition: theme.transitions.create(['background-color'], {
						//   duration: theme.transitions.duration.standard,
						// }),
						// transition: 'background-color 1.3s',

						// backgroundColor: bgcolor,
						// backgroundColor: theme.palette.background.paper,
						backgroundColor: bgColor,
					}}
					// elevation={trigger ? 1 : 0}
					elevation={0}
				>
					<Container paddingY={isMd ? 1.5 : 1.1}>
						<Topbar
							onSidebarOpen={handleSidebarOpen}
							pages={pages}
							colorInvert={trigger ? false : colorInvert}
						/>
					</Container>
					<Suspense fallback={<></>}>
						<Loader />
					</Suspense>
				</AppBar>
				{(IS_MOBILE || (loaded && !isMd)) && (
					<Suspense fallback={<></>}>
						<Sidebar onClose={handleSidebarClose} open={open} variant='temporary' pages={pages} />
					</Suspense>
				)}
				<main>
					<Scroll.Element name='top-bar' />
					{children}
					<Divider />
				</main>
				<Box bgcolor={'background.footer'}>
					<Suspense fallback={<></>}>
						<Container paddingY={isMd ? 4 : 2} paddingTop={isMd ? 5 : isSm ? 2 : 3}>
							<Footer />
						</Container>
					</Suspense>
				</Box>
			</Box>
		);
	},
);

export { Main };
