import { Button } from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';
import {
	ShieldOutlined as ShieldOutlinedIcon,
	RemoveModeratorOutlined as RemoveModeratorOutlinedIcon,
} from '@mui/icons-material';
// import RemoveModeratorOutlinedIcon from '@mui/icons-material/RemoveModeratorOutlined';
import { useCookieConsent } from '~/content/globalStore/ccStore';
import { trackEvent } from '~/utils/plausible';
import { ClientOnly } from 'remix-utils/client-only';
import { useHydrated } from 'remix-utils/use-hydrated';

const CCToggler = (): JSX.Element => {
	const { cookieConsentBoxVisible, setCookieConsentBoxVisible } = useCookieConsent();

	const theme = useTheme();
	// const { themeToggler } = theme;
	const { mode } = theme.palette;
	let isHydrated = useHydrated();

	return (
		// <ClientOnly>
		// 	{() => (
		<Button
			variant={'outlined'}
			onClick={() => {
				setCookieConsentBoxVisible(!cookieConsentBoxVisible);
				trackEvent('cookie-consent-toggler');
			}}
			aria-label='Cookie consent toggler'
			color={mode === 'light' ? 'primary' : 'secondary'}
			sx={{
				borderRadius: 0,
				minWidth: 'auto',
				padding: 0.5,
				borderColor: alpha(theme.palette.divider, 0.2),
			}}
		>
			{cookieConsentBoxVisible && isHydrated ? (
				<RemoveModeratorOutlinedIcon
					sx={{
						fontSize: '20px',
					}}
				/>
			) : (
				<ShieldOutlinedIcon
					sx={{
						fontSize: '20px',
					}}
				/>
			)}
		</Button>
		// 	)}
		// </ClientOnly>
	);
};

export { CCToggler };
