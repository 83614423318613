import { Button, Typography } from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';
import {
	useSubmit,
	useLocation,
	useNavigate,
	useLoaderData,
	useNavigation,
	useActionData,
	useFetcher,
} from '@remix-run/react';

// import { trackEvent } from '~/utils/plausible';
// import i18next from 'i18next';
// import { useChangeLanguage } from 'remix-i18next/react';
import { useTranslation } from 'react-i18next';
import i18n_config from '~/i18n';

import { useEffect } from 'react';

const LanguageToggler = (): JSX.Element => {
	let { i18n } = useTranslation();
	const language = i18n.language;

	// const [newLangSet, setNewLangSet] = React.useState<any>(language);
	// const [toggle, setToggle] = React.useState<boolean>(false);

	const submit = useSubmit();
	let location = useLocation();
	const theme = useTheme();
	//@ts-ignore
	const { themeToggler } = theme;
	const { mode } = theme.palette;

	// const language = i18next.language;

	const navigate = useNavigate();
	const navigation = useNavigation();
	const nav_location = navigation.location;
	const data = useLoaderData();
	// console.log(data.userLanguage);
	// console.log(location.pathname);

	// let fetcher = useFetcher({ key: "add-to-bag" });
	let fetcher = useFetcher({ key: 'languageToggle' });
	// console.log(fetcher);

	//* lang switch cleanup
	// React.useEffect(() => {
	//   // console.log({ lngJustSwitched });
	//   return () => {
	//     setLngJustSwitched(false);
	//   };
	// }, [lngJustSwitched]);

	useEffect(() => {
		//   // console.log({ lngJustSwitched });
		//   if (newLangSet !== language) {
		//     setNewLangSet(language);
		//     console.log(language);
		//     setLngJustSwitched(false);
		//   } else {
		//     console.log(lngJustSwitched);
		//   }
		//   // else {
		//   //   console.log('no change');
		//   // }
		//   // return () => {
		//   //   setLngJustSwitched(false);
		//   // };
		// console.log(navigation.location);
		// console.log(location.pathname);
		if (navigation.location) {
			const navigator_split_cleaned = navigation.location.pathname
				.split('/')
				.filter((item) => item !== '' && !i18n_config.supportedLngs.includes(item));
			// console.log(navigator_split_cleaned);

			const location_split_cleaned = location.pathname
				.split('/')
				.filter((item) => item !== '' && !i18n_config.supportedLngs.includes(item));
			// console.log(location_split_cleaned);

			const pageRoot = location_split_cleaned.length === 0 && navigator_split_cleaned.length === 0;

			//* check if they are the same
			// const sameSite = navigator_split_cleaned.every((item, index) => item === location_split_cleaned[index]);
			const sameSite = navigator_split_cleaned.includes(location_split_cleaned[0]) || pageRoot;
			// console.log({ sameSite });

			if (!sameSite) {
				// console.log(location.pathname);
				// console.log(nav_location?.pathname);
				// console.log(language);
				if (
					language === 'de' &&
					nav_location?.pathname.includes('/en/') &&
					nav_location?.pathname
				) {
					console.log('de -> en');
					// console.log(nav_location.pathname.replace('/en/', '/'));
					// setLngJustSwitched(true);
					navigate(nav_location.pathname.replace('/en/', '/'), {
						replace: true,
						preventScrollReset: true,
					});
				} else if (
					language === 'en' &&
					!nav_location?.pathname.includes('/en/') &&
					nav_location?.pathname
				) {
					console.log('en -> de');
					// console.log(nav_location.pathname.replace('/', '/en/'));
					navigate(nav_location.pathname.replace('/', '/en/'), {
						replace: true,
						preventScrollReset: true,
					});
				}
			}
		}
	}, [language, navigation]);

	return (
		<Button
			variant={'outlined'}
			// id='languageTogglerButton'
			onClick={(e) => {
				// e.preventDefault();
				// setLngJustSwitched(true);
				const newLng = language === 'en' ? 'de' : 'en';
				//? submit form to server
				// const formData = new FormData();
				// formData.append('themeMode', mode === 'light' ? 'dark' : 'light');
				// formData.append('userLanguage', newLng);
				// console.log(location.pathname);
				// submit(formData, {
				submit(
					{ userLanguage: newLng },
					{
						navigate: false,
						fetcherKey: 'languageToggle',
						method: 'post',
						action: location.pathname,
						// action: '/',
						// encType: 'multipart/form-data',
						// encType: 'application/json',
					},
				);

				//* navigate to new language on same page
				// let inclueds_EN = location.pathname.includes('/en');
				// let inclueds_DE = location.pathname.includes('/de');
				let url_split_1 = location.pathname.split('/')[1];
				let url_split_2 = location.pathname.split('/')[2];
				let url_split_3 = location.pathname.split('/')[3];
				const root_location = url_split_1 === '';
				// console.log({ url_split_1, url_split_2, url_split_3 });
				// console.log({ language });
				// console.log(location.pathname);

				var new_loaction = '';
				if (language === 'en') {
					// if (root_location) {
					//   new_loaction = location.pathname.replace('/', '/de');
					// } else {
					new_loaction = location.pathname.replace('/en', '');
					// }
					// console.log({ new_loaction });
					// new_loaction = new_loaction.replace('/', '/de');
					// navigate(location.pathname.replace('/en/', '/de/'), {

					// navigate(new_loaction, {
					// 	replace: true,
					// 	preventScrollReset: true,
					// });
					navigate(new_loaction, {
						replace: true,
						preventScrollReset: true,
					});
				} else if (language === 'de') {
					// console.log({ root_location });
					if (root_location) {
						new_loaction = location.pathname.replace('/', '/en/');
					} else if (!location.pathname.includes('/en/')) {
						new_loaction = '/en' + location.pathname;
					} else {
						new_loaction = location.pathname;
					}
					// console.log({ new_loaction });
					// console.log({ new_loaction });
					// new_loaction = new_loaction.replace('/', '/en');
					// console.log({ new_loaction });

					// navigate(new_loaction, {
					// 	// navigate(location.pathname.replace('/de/', '/en/'), {
					// 	replace: true,
					// 	preventScrollReset: true,
					// });
					navigate(new_loaction, {
						// navigate(location.pathname.replace('/de/', '/en/'), {
						replace: true,
						preventScrollReset: true,
					});
				}

				//  else {
				//   navigate(location.pathname.replace('/', '/en/'), {
				//     replace: true,
				//     preventScrollReset: true,
				//   });
				// }
				// const navigator = (location: string, language: string) => {

				// };

				// i18n.changeLanguage(newLng);

				// trackEvent('theme-mode', {
				//   props: {
				//     // time: new Date().toLocaleString(),
				//     variation: mode === 'light' ? 'dark' : 'light',
				//   },
				// });
				//!--->
				// console.log(data.lang);
				// console.log(language);
				// console.log({ location });
				// replace location.lang with the opposite
				// console.log({ newLng });
				// i18next.changeLanguage(newLng, (err, t) => {
				//   if (err) return console.log('something went wrong loading', err);

				//   navigate(location.pathname.replace(data.lang, newLng), {
				//     replace: true,
				//   });
				// });
				// useChangeLanguage(newLng);
				// i18n.changeLanguage(newLng);
				// i18next.changeLanguage(newLng).then((t) => {
				//   // t('key'); // -> same as i18next.t
				//   // navigate(location.pathname.replace(data.lang, newLng), {
				//   //   replace: true,
				//   // });
				// });
			}}
			aria-label='DE/EN'
			color={mode === 'light' ? 'primary' : 'secondary'}
			sx={{
				borderRadius: 0,
				minWidth: 'auto',
				padding: 0.5,
				borderColor: alpha(theme.palette.divider, 0.2),
				width: '30px',
				height: '30px',

				// center children (text)
				display: 'flex',
				justifyContent: 'center',
			}}
		>
			{language === 'en' ? (
				<Typography
					color='currentColor'
					className='safari-only-pos-1'
					sx={{
						width: '100%',
						height: '100%',
						fontSize: '0.9rem',
						fontWeight: 500,
					}}
				>
					DE
				</Typography>
			) : (
				<Typography
					color='currentColor'
					className='safari-only-pos-1'
					sx={{
						width: '100%',
						height: '100%',
						fontSize: '0.9rem',
						fontWeight: 500,
					}}
				>
					EN
					{/* {language?.toUpperCase()} */}
				</Typography>
			)}
		</Button>
	);
};

export { LanguageToggler };
